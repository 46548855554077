<div class="tools">
    <vue-resizable class="tools__wrapper" ref="tools" v-bind="sizeOptions">
        <div class="tools__move">
            <drag-icon/>
        </div>

        <div 
            class="tools__item"
            v-for="(item, index) in tools"
            :key="index"
            :class="item.name, {active: (index == selectedTool)}"
            @click="selectTool(index);">

            <component v-if="item.icon" :is="item.icon"/>
        </div>
    </vue-resizable>
</div>