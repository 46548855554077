<header id="header" class="header">
    <div class="header__content">
        <div class="header__logo">
            <img :src="require(`/src/assets/images/logo.svg`)" alt="Logo" class="img-resp">
        </div>

        <div class="header__controls">
            <button id="save-mix" class="header__btn">
                <img :src="require(`/src/assets/images/save-mix.svg`)" alt="save-mix" class="img-resp">
            </button>

            <button id="cancel-mix" class="header__btn">
                <img :src="require(`/src/assets/images/cancel-mix.svg`)" alt="cancel-btn" class="img-resp">
            </button>
            
            <div class="header__user">
                <a href="#" id="user-profile">
                    <img :src="require(`/src/assets/images/profile.svg`)" alt="profile" class="img-resp">
                </a>
            </div>
        </div>
    </div>
</header>