'use strict';

import VueResizable from 'vue-resizable';
import SelectIcon from '../icons/SelectIcon.vue';
import ZoomInIcon from '../icons/ZoomInIcon.vue';
import ZoomOutIcon from '../icons/ZoomOutIcon.vue';
import FitIcon from '../icons/FitIcon.vue';
import CutIcon from '../icons/CutIcon.vue';
import MagnetLeftIcon from '../icons/MagnetLeftIcon.vue';
import MagnetRightIcon from '../icons/MagnetRightIcon.vue';
import UndoIcon from '../icons/UndoIcon.vue';
import DoIcon from '../icons/DoIcon.vue';
import DragIcon from '../icons/DragIcon.vue';

export default({
    VueResizable,
    SelectIcon,
    ZoomInIcon,
    ZoomOutIcon,
    FitIcon,
    CutIcon,
    MagnetLeftIcon,
    MagnetRightIcon,
    UndoIcon,
    DoIcon,
    DragIcon
});