'use strict';

import tools from "./watch/tools";

export default({
    tools,
    mainTimerSeconds() {
        if (!this.currentTrack.time_watching) return false;
        document.querySelectorAll('.mixer-track').forEach(elem => {
            const trackRect = elem.getBoundingClientRect();
            const trackId = parseInt(elem.getAttribute('id').match(/\d+/)[0]) - 1;
            if (this.currentTrack.progress >= trackRect.left && this.currentTrack.progress <= trackRect.right && this.seekTime && !this.currentTrack.indexes.includes(trackId)) {
                this.currentTrack.indexes = [...this.currentTrack.indexes, trackId];
            }
        });
    },
})
