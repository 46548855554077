'use strict';

import data       from "./js/data";
import components from "./js/components";
import methods    from "./js/methods";
import mounted    from "./js/mounted";

export default {
    name: 'App',
    data,
    components,
    methods,
    mounted
}