'use strict';



import setZoomTime from "@/components/workspace/js/methods/setZoomTime";


export default function() {
    document.title = `ROVR Mixer - ${this.program.show.title} by ${this.program.curator.name}`;

    this.containerWidth = 490;
    //this.isHere = false;
    this.isHere = true;
    this.containerZoomWidth = 1475;
    this.lastCount =  setZoomTime(300);
    this.left = 1300;
    this.lastLeft = 0;

}
