'use strict';

export default function toolHandler() {
    let elem = this.$refs.mixerTrack;

    if(elem.l <= 1 ) {
        elem.style.left = `1px`;
    }

    elem.w < 150 ? this.smallSize = true : this.smallSize = false;
}