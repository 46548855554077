'use strict';

import axios from 'axios';

export default async function() {
    this.publisher_id = this.getParams('id');

    await axios.get(`https://app.rovr.live/site/playlist/${this.publisher_id}?query=test`)
        .then(response => {
            this.program = response.data;

        })
        .catch(err => {
            this.error = true;
            console.error(err);
        });
}
