'use strict';

export default({
    ready: Boolean,
    play: Boolean,
    position: Number,
    duration: Number,
    currentTrackIndex: Number,
    tracks: Array,
    bpm: Number,
    key: Number,
})
