<div id="workspace" v-if="program">
    <controls
    @play="currentTrack.play = !currentTrack.play; currentTrack.stop = false;"
    @stop="stop()"
    :ready="ready"
    :play="currentTrack.play"
    :position="currentTrack.position"
    :duration="timeFormat('ss', program.duration)"
    :currentTrackIndex="currentTrack.index"
    :tracks="program.tracks"
    :bpm="currentTrack.bpm"
    :key="currentTrack.key"/>

    <section id="workspace-area">
        <div id="progress-cursor" :style="{left: `${currentTrack.progress}px`}"></div>

        <workspaceInfo
        :duration="program.duration"
        :tracks="program.tracks.length"
        :title="program.show.title"
        :author="program.curator.name"
        :channels="channels"
        :currentSingleChannel="currentSingleChannel"
        @selectChannel="index => selectChannel(index)"/>
<!--        <div id="multiTrackBox"></div>-->
        <div id="workspace-player" :style="`margin-left: -${ containerZoomWidth > 50000 ? (newWidth2*containerZoomWidth)/100 - 2 : 13}px`" ref="scroll" @click="e => seek('outside', e, null)" @wheel.prevent="e => {
            e.preventDefault()
            let local = lastCount;
            zoom(e);

            if(local !== lastCount){
                this.isHere = false;
                this.isHere = true;
            }
            console.log(lastLeft, 123321)
            onWhiil()
            //this.isHere = true;
        }">
            <div id="workspace-timeline" ref="timeline" :style="workspaceTimelineStyle">

                <div :style="`display:grid;margin-left:${newCount};width: ${newWidth};position: relative;`" v-if="isHere" v-for="(item,index) in timeline()" :key="index">
                    <div  class="playlist-timeline-item"  style="position: relative;"  >
                    <p v-if="item==='grid'" :class="`${index > 2 ? 'p2' : 'p3'}`" v-text=" "/>
                    <p v-if="item!=='grid'"  v-text="item" />
                    </div>
                    <div  v-if="index % 2 !== 0 && index > 2" style="z-index:0;top:100vh;width:100%;height: 100vh;background: #43434333; position: relative;color: #fff6f65e"></div>
                </div>

            </div>

            <div id="workspace-playlist-wave"/>

            <div id="workspace-channels" v-if="isHere" class="workspace-channels" ref="tracks">
                <div class="channel" v-if="isHere" v-for="(channel, index) in splitedChannels" :key="index" :class="`channel-${index+1}`">
                    <track-component v-if="isHere" v-for="(track, index) in channel.trackList"
                        :key="index"
                        :track="track"
                        :currentTrack="currentTrack"
                        :playIndex="currentTrack.index"
                        :playIndexes="currentTrack.indexes"
                        :playStatus="currentTrack.play"
                        :stop="currentTrack.stop"
                        :scaleSize="scale"
                        :activeTool="activeTool"
                        :seekTime="seekTime"
                        :tracksLength="program.tracks.length"
                        :dynamicWidth="trackWidth(track.pivot.order)"
                        :dynamicOffset="trackLeft(track.pivot.order) + (containerZoomWidth > 50000 ? (newWidth2*containerZoomWidth)/100 : 13)"
                        @ready="ready = true;" @seek="i => seek('inside', null, i)"
                        @nextTrack="i => currentTrack.index = i + 1"
                        @progress="e => currentTrack.progress = e"
                        @position="e => currentTrack.position = e"
                        @finish="stop()"
                        @duration="e => currentTrack.duration = e"
                        @bpm="e => currentTrack.bpm = e"
                        @key="e => currentTrack.key = e"
                    />
                </div>
            </div>
        </div>
    </section>
</div>
