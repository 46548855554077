<vue-resizable
    :id="`mixer-track-${track.pivot.order+1}`"
    ref="mixerTrack"
    class="mixer-track"
    :class="activeTool, {small: smallSize}"
    v-bind="sizeOptions"
    :width="dynamicWidth"
    :left="dynamicOffset"
    :active="activeTool === 'cut' ? ['l', 'r'] : []"
    :dragSelector="activeTool === 'select' ? '.mixer-track__info' : undefined"
    @drag:move="toolHandler"
    @resize:move="toolHandler"
    @wheel.prevent="onWheel($event, track)"
    :style="{borderColor: track.color}">
    <div class="mixer-track__info" :style="{backgroundColor: track.color}">
        <div class="mixer-track__details">
            <p v-text="trackInfo" :title="trackInfo"/>
            <span v-text="trackDetails" :title="trackDetails"/>
        </div>
        <div class="mixer-track__controls">
            <div class="mixer-track-controls__pan">
                <div class="control-circle pan-circle">
                    <span class="level" :style="{transform: `rotate(${panLevel}deg)`}"/>
                </div>

                <div class="control-pan-btns">
                    <span @click="panLevel -= 36; panLevel == -360 ? panLevel = 0 : panLevel = panLevel;">-</span>
                    <span @click="panLevel += 36; panLevel ==  360 ? panLevel = 0 : panLevel = panLevel;">+</span>
                </div>
            </div>

            <div class="mixer-track-controls__mute" @click="track.muted = !track.muted; WaveSurfer.setMuted(track.muted);">
                <div class="control-circle mute-circle">
                    <img v-show="track.muted" :src="require(`/src/assets/images/track-muted.svg`)" alt="muted">
                    <img v-show="!track.muted" :src="require(`/src/assets/images/track-unmuted.svg`)" alt="muted">
                </div>
                <p v-text="this.track.muted ? 'unmute' : 'mute'"/>
            </div>

            <div class="mixer-track-controls__color">
                <OnClickOutside @trigger="track.pickColor = false;">
                    <div @click="track.pickColor = !track.pickColor">
                        <div class="control-circle color-circle">
                            <img :src="require(`/src/assets/images/color-piker.svg`)" alt="color piker">
                        </div>
                        <p>color</p>
                    </div>

                    <ColorPicker
                    v-if="track.pickColor"
                    @pick="color => {track.color = color; WaveSurfer.setOptions({progressColor: color}); track.pickColor = false;}"/>
                </OnClickOutside>
            </div>
        </div>
    </div>

    <div class="mixer-track__wave" :id="`mixer-track-wave-${track.pivot.order+1}`"/>
</vue-resizable>
