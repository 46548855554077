<div id="workspace-info">
    <div class="workspace-info__column">
        <div class="workspace-info__details">
            <p class="workspace-text-gray">Playlist time: <span v-text="duration"/></p>
            <p class="workspace-text-gray">Tracks: <span v-text="tracks"/></p>
        </div>
        <div class="workpace-info__amp"></div>
    </div>

    <div class="workspace-info__column">
        <div class="workspace-info__details">
            <p class="workspace-text-yellow uppercase" v-text="title"/>
            <p class="workspace-text-yellow">BY: <span v-text="author"/></p>
            <p class="workspace-text-gray">Upload: <span v-text="`Not Yet`"/></p>
            <p class="workspace-text-gray">Last Save: <span v-text="`20.07.2023`"/></p>
        </div>
        <div class="workpace-info__amp">
            <amp/>
        </div>
    </div>

    <div class="workspace-info__column channel" v-for="(channel, index) in channels" :key="index">
        <div class="workspace-info__details">
            <span class="channel-btn" v-text="`channel ${channel.id < 9 ? '0' + (channel.id+1) : (channel.id+1)}`"/>
            <button class="channel-single-btn" :class="{active: (index == currentSingleChannel)}"
            @click="$emit('selectChannel', index)" v-text="`s`"/>
        </div>
        <div class="workpace-info__amp">
            <amp/>
        </div>
    </div>

    <div class="workspace-info__column add-channel-row">
        <div class="workspace-info__details">
            <div class="add-channel">+</div>
        </div>
        <div class="workpace-info__amp"></div>
    </div>
</div>