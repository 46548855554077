'use strict';

import data       from './js/data';
import props      from './js/props';
import computed   from './js/computed';
import mounted    from './js/mounted';
import components from './js/components';
import watch      from './js/watch';
import methods    from './js/methods';

export default {
    name: 'Track',
    data,
    props,
    computed,
    mounted,
    components,
    watch,
    methods
}