'use strict';

export default function trackLeft(index) {
    let left = 0;
    for (let i = 0; i < index; i++) {
        const trackWidth = this.trackWidth(i);
        left += trackWidth;
    }
    return left;
}
