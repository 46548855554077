'use strict';

import setZoomTime from "@/components/workspace/js/methods/setZoomTime";

export default ({
    deep: true,
    handler() {
        this.activeTool = this.tools.find(tool => tool.isActive).name;
        if(this.activeTool === 'fit') {
            this.scale = 3;

            //this.isHere = false;
            this.containerWidth = 490;
            this.isHere = true;
            this.containerZoomWidth = 1475;
            this.lastCount =  setZoomTime(300);
            this.left = 1475;

        }
    }
});