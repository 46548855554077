'use strict';

import Controls       from '/src/components/controls/Controls.vue';
import WorkspaceInfo  from './components/info/WorkspaceInfo.vue';
import TrackComponent from './components/track/Track.vue';

export default({
    Controls,
    WorkspaceInfo,
    TrackComponent,
})