'use strict';

import components from './components';
import { markRaw } from 'vue';

export default function() {
    return {
        selectedTool: 0,
        sizeOptions: {
            top: 0,
            left: 0,
            width: 'auto',
            height: 50,
            active: [],
            dragSelector: '.tools__move'
        },
        tools: [
            {
                name: 'select',
                isActive: true,
                icon: markRaw(components.SelectIcon),
            },
            {
                name: 'zoom',
                isActive: false,
                icon: markRaw(components.ZoomInIcon),
            },
            {
                name: 'fit',
                isActive: false,
                icon: markRaw(components.FitIcon),
            },
            {
                name: 'zoomOut',
                isActive: false,
                icon: markRaw(components.ZoomOutIcon),
            },
            {
                name: 'cut',
                isActive: false,
                icon: markRaw(components.CutIcon),
            },
            {
                name: 'magnet-left',
                isActive: false,
                icon: markRaw(components.MagnetLeftIcon),
            },
            {
                name: 'magnet-right',
                isActive: false,
                icon: markRaw(components.MagnetRightIcon),
            },
            {
                name: 'undo',
                isActive: false,
                icon: markRaw(components.UndoIcon),
            },
            {
                name: 'do',
                isActive: false,
                icon: markRaw(components.DoIcon),
            },
        ]
    }
}