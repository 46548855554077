'use strict';

export default function timeFormat(mode, time) {
    if(mode === 'ss') {
        let a = time.split(':');
        let seconds = (+a[0]) * 60 + (+a[1]); 
        return seconds;
    } else if(mode === 'mss') {
        return time<60 ? time : (time>=60?(time-(time%=60))/60:'')+(time>9?':':':0')+time;
    }
}