'use strict';

export default function() {
    return {
        plugins: {
            envelope: null,
            regions: null,
        },
        sizeOptions: {
            maxWidth: 450,
            minWidth: 50,
            disableAttributes: ['t', 'h'],
        },
        smallSize: false,
        position: 0,
        progress: 0,
        duration: 0,
        panLevel: 0,
    }
}