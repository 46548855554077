'use strict';

import WaveSurfer from 'wavesurfer.js';
// import sound_1 from '/src/assets/audio/hold.mp3';
// import sound_2 from '/src/assets/audio/test.mp3';

export default function createTrack() {
    this.WaveSurfer = WaveSurfer.create({
        container: `#mixer-track-wave-${this.track.pivot.order+1}`,
        // url: this.track.pivot.order % 2 === 0 ? sound_1 : sound_2,
        url: `https://app.rovr.live/api/track/${this.track.uuid}/play`,
        fetchParams: {headers: {'Authorization' : 'Bearer 1e10f824-8fb2-4951-9815-d84d7bb141f5'}},
        waveColor: '#9B9B9B',
        progressColor: '#FFF057',
        height: 95,
        autoScroll: true,
        autoCenter: true,
        barGap: 1.5,
        barWidth: 1.,
        dragToSeek: true,
        hideScrollbar: true,
    });

    this.createEnvelope();

    this.WaveSurfer.addEventListener('ready', () => {
        this.WaveSurfer.on('click', () => {
            this.WaveSurfer.play();
            this.$emit('seek', this.track.pivot.order);
        });

        this.duration = Math.round(this.WaveSurfer.getDuration());

        if(this.playIndex == this.track.pivot.order) {
            this.$emit('duration', this.duration);
            this.$emit('bpm', this.track.bpm);
            this.$emit('key', this.track.key);
            this.$emit('ready');
        }

        this.envelope.addPoint({time: this.duration - 10, volume: 0.8});
        this.createCut();
    });

    this.WaveSurfer.addEventListener('timeupdate', () => {
        this.position = this.WaveSurfer.getCurrentTime();
        this.progress = this.WaveSurfer.getCurrentTime();
    });

    this.scaleSize < 150 ? this.smallSize = true :  this.smallSize = false;
}
