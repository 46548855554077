'use strict';

export default function playStatus(status) {
    // if (status == true && this.playIndex == this.track.pivot.order) {
    //     this.WaveSurfer.play();
    // } else {
    //     this.WaveSurfer.pause();
    // }
    if (!status) this.WaveSurfer.pause()
    else if(this.playIndexes.includes(this.track.pivot.order)) {
        this.WaveSurfer.play();
    }
}
