'use strict';

export default function timeFormat(mode, time) {
    if(mode === 'ss') {
        let a = time.split(':');
        let seconds = (+a[0]) * 60 + (+a[1]); 
        return seconds;
    } else if(mode === 'mss') {
        let minutes = Math.floor(time / 60);
        let seconds = Math.floor(time % 60);
        let milliseconds = Math.floor((time % 1) * 1000);

        let formattedMinutes = minutes.toFixed().padStart(2, '0');
        let formattedSeconds = seconds < 10 ? `0${seconds}` : `${seconds}`;
        let formattedMilliseconds = milliseconds.toFixed(2).padStart(5, '0').slice(0, 2);

        return `${formattedMinutes}:${formattedSeconds}:${formattedMilliseconds}`;
    }
}