'use strict';

import MixerLayout from '/src/layouts/mixer/MixerLayout.vue';
import Header      from '/src/components/header/Header.vue';
import Tools       from '/src/components/tools/Tools.vue';
import Workspace   from '/src/components/workspace/Workspace.vue';

export default({
    MixerLayout,
    Header,
    Tools,
    Workspace
});