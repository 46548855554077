'use strict';

import selectChannel from "./methods/selectChannel";
import timeFormat    from './methods/timeFormat';
import stop          from './methods/stop';
import seek          from "./methods/seek";
import zoom          from "./methods/zoom";
import trackLeft     from "./methods/trackLeft";
import trackWidth    from "./methods/trackWidth";
import setZoomTime    from "./methods/setZoomTime";
import timeline, {getLength} from "./methods/timeline";

export default({
    timeline,
    selectChannel,
    timeFormat,
    stop,
    seek,
    zoom,
    trackLeft,
    trackWidth,
    setZoomTime,
    getLength,
    onWhiil(){
        const workspacePlayer = document.getElementById('workspace-player');

        workspacePlayer.scrollLeft +=(this.lastLeft/2);
    },
    getBlockIndexAtCursorPosition(cursorX) {
        const tracksRect = [];

        document.querySelectorAll('.mixer-track').forEach(track => {
            const rect = track.getBoundingClientRect();
            const trackCoordinates = {
                startX: rect.left,
                width: rect.width,
                endX: rect.left + rect.width,
                id: track.getAttribute('id')
            }
            tracksRect.push(trackCoordinates);
        });

        return tracksRect.find((track) => {
            return cursorX >= track.startX && cursorX <= track.endX;
        });
    },
});
