'use strict';

export default function timerPosition() {
    if (this.duration && this.position) {
        let previousTracksDuration = 0;

        for (let i = 0; i < this.currentTrackIndex; i++) {
          previousTracksDuration += this.timeFormat('ss', this.tracks[i].duration);
        }

        if(this.since) {
            return this.timeFormat('mss', previousTracksDuration + this.position);
        } else {
            return `-${this.timeFormat('mss', this.duration - (previousTracksDuration + this.position))}`
        }
    }

    return this.since ? '00:00:00' :  '-00:00:00';
}
