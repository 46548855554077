'use strict';

export default function timeFormat(mode, time) {
    if(mode === 'ss') {
        let a = time.split(':');
        let seconds = (+a[0]) * 60 + (+a[1]); 
        return seconds;
    } else if(mode === 'mss') {
        return time<60 ? time : (time>=60?(time-(time%=60))/60:'')+(time>9?':':':0')+time;
    } else if(mode === 'mss:ms') {
        let minutes = Math.floor(time / 60);
        let seconds = Math.floor(time % 60);
        let milliseconds = Math.floor((time % 1) * 1000);

        if (milliseconds !== 250 && milliseconds !== 750) {
            milliseconds = Math.round(milliseconds / 100) * 100;
        }

        if (milliseconds === 1000) {
            seconds++;
            milliseconds = 0;
        }

        const formattedMinutes = minutes.toFixed().padStart(2, '0');
        const formattedSeconds = (seconds < 10) ? `0${seconds}` : `${seconds}`;
        const formattedMilliseconds = (milliseconds > 0 || this.splitSeconds < 1) ? `:${milliseconds.toString().padStart(3, '0')}` : '';

        return `${formattedMinutes}:${formattedSeconds}${formattedMilliseconds}`;
    }
}