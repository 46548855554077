'use strict';

export default function selectTool(i) {
    this.selectedTool = i;

    this.tools.forEach((tool, index) => {
        tool.isActive = (index === i)
    });

    //let activeTool = this.tools.find(tool => tool.isActive);

    this.$emit('tools', this.tools);
}