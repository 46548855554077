'use strict';

export default function seek(from, event, index) {
    if(from === 'outside') {
        this.currentTrack.time_watching = false;
        const isTrack = event.target.closest('.mixer-track');

        if(!event.target.parentNode.classList.contains('mixer-track__wave') && !isTrack) {
            const tracksContainer = document.querySelector('#workspace-player');
            const x = 250 + event.pageX - tracksContainer.getBoundingClientRect().left;
            this.currentTrack.indexes = [];
            this.currentTrack.positions = {};
            document.querySelectorAll('.mixer-track').forEach(elem => {
                const trackRect = elem.getBoundingClientRect();
                const trackId = parseInt(elem.getAttribute('id').match(/\d+/)[0]) - 1;
                // const track = this.program.tracks.find(item => item.id === trackId);
                if (x >= trackRect.left && x <= trackRect.right) {
                    this.currentTrack.index = trackId;
                    const trackWidth = trackRect.width;
                    const relativeX = x - trackRect.left;
                    const percentage = relativeX / trackWidth;
                    const trackDuration = this.currentTrack.duration;
                    this.seekTime = Math.round(trackDuration * percentage);
                    if(this.seekTime) {
                        this.currentTrack.play = true;
                        this.currentTrack.stop = false;
                        this.currentTrack.progress = x;
                        this.currentTrack.indexes.push(trackId);
                        this.currentTrack.positions[trackId] = this.seekTime;
                    }
                }
            });
        } else {
            return;
        }
        setTimeout(() => {
            this.currentTrack.time_watching = true;
        }, 100);
    }
    else if(from === 'inside') {
        this.currentTrack.play  = true;
        this.currentTrack.stop  = false;
        this.currentTrack.index = index;
        this.currentTrack.indexes.push(index);
    }
}
