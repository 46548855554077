'use strict';


import setZoomTime from "@/components/workspace/js/methods/setZoomTime";
let arrCount = 0;
export default function timeline() {
    const splittedTimeline = [];
    const duration = Number(this.timeFormat("ss", this.program.duration));
    let zm = setZoomTime()
    for (let t = 0; t < duration; t += zm) {

        if(t !== 0) {
            if (this.left >= 50000) {
                console.log("grid")
            } else if (this.left >= 30000) {


                splittedTimeline.push('grid');
                splittedTimeline.push('grid');

            } else if (this.left >= 20000) {

                splittedTimeline.push('grid');
                splittedTimeline.push('grid');
                splittedTimeline.push('grid');

            } else {

                splittedTimeline.push('grid');
                splittedTimeline.push('grid');
                splittedTimeline.push('grid');
                splittedTimeline.push('grid');


            }
        } else {
            splittedTimeline.push('grid');
            splittedTimeline.push('grid');
        }

        splittedTimeline.push(this.timeFormat("mss:ms", Math.min(t, duration)));

        if(this.left >=150000){
            continue;
        } else if(this.left >=50000){
            if(t !== 0){
                splittedTimeline.push('grid');
            } else {

                splittedTimeline.push('grid');
            }
            continue;
        } else if(this.left >= 30000){
            if(t !== 0){
                splittedTimeline.push('grid');
                splittedTimeline.push('grid');
            } else {

                splittedTimeline.push('grid');
                splittedTimeline.push('grid');
            }
            continue;
        } else if(this.left >= 20000){
            if(t !== 0){
                splittedTimeline.push('grid');
                splittedTimeline.push('grid');
                splittedTimeline.push('grid');
            } else {
                splittedTimeline.push('grid');
                splittedTimeline.push('grid');
                splittedTimeline.push('grid');
            }
            continue;
        } else {
            splittedTimeline.push('grid');
            splittedTimeline.push('grid');
            splittedTimeline.push('grid');
            splittedTimeline.push('grid');
            continue;
        }


    }
    //console.log(splittedTimeline)
    arrCount = splittedTimeline.length;
    this.newCount = `${-2* (100 /(arrCount - 1))}%`;
    this.newWidth = `${100/(arrCount - 1)}%`;
    this.newWidth2 = (100/(arrCount - 1));
    return splittedTimeline;
}

export function getLength() {

    return arrCount;
}
