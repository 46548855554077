'use strict';

export default function position(time) {
    let pos = Math.ceil(time);

    if(pos === this.duration) {
        this.WaveSurfer.pause();
        this.$emit('nextTrack', this.playIndex);
    }

    if(pos === this.duration && this.playIndex === (this.tracksLength - 1)) {
        this.$emit('finish');
    }

    this.$emit('position', this.position);
}
