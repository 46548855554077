'use strict';

export default({
    track: Object,
    currentTrack: Object,
    activeTool: String,
    playIndex: Number,
    playIndexes: Array,
    playStatus: Boolean,
    stop: Boolean,
    scaleSize: Number,
    seekTime: Number,
    tracksLength: Number,
    dynamicWidth: Number,
    dynamicOffset: Number
})
