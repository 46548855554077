'use strict';

export default function() {
    return {
        since: true,
        selectedMode: 'time',
        modes: [
            {
                id: 0,
                name: 'bpm',
                isActive: false,
            },
            {
                id: 1,
                name: 'key',
                isActive: false,
            },
            {
                id: 2,
                name: 'time',
                isActive: true,
            },
        ]
    }
}