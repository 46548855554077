'use strict';


import splitedChannels from "./computed/splitedChannels";
import setZoomTime  from "./methods/setZoomTime";
export default({
    splitedChannels,
    setZoomTime,
    workspaceTimelineStyle() {
        return {
            width: this.containerZoomWidth ? `${this.containerZoomWidth}px` :'auto',
        };
    },
    mainTimerSeconds() {
        if (this.program.duration && this.currentTrack.position) {
            let previousTracksDuration = 0;
            for (let i = 0; i < this.currentTrack.index; i++) {
                previousTracksDuration += this.timeFormat('ss', this.program.tracks[i].duration);
            }
            return previousTracksDuration + this.currentTrack.position;
        }

        return 0;
    }
})
