'use strict';

import components from "./js/components";
import data       from "./js/data";
import props      from "./js/props";

export default {
    name: 'Mixer',
    components,
    data,
    props
}