'use strict';

import Regions from 'wavesurfer.js/dist/plugins/regions';

export default function createCut() {
    this.regions = this.WaveSurfer.registerPlugin(Regions.create());

    let lastSecond = Math.floor(this.WaveSurfer.getDuration());

    this.regions.addRegion({
        start: 0,
        end: lastSecond,
        color: 'rgba(255, 240, 87, 0.5)',
        drag: true,
        resize: true,
    });

    this.regions.addEventListener('region-out', region => {
        region.play();
    });
}