'use strict';

import playStatus from "./watch/playStatus";
import playIndex  from "./watch/playIndex";
import position   from "./watch/position";
import progress   from "./watch/progress";
import stop       from './watch/stop';
import scaleSize  from "./watch/scaleSize";
import seekTime   from "./watch/seekTime"

export default({
    playStatus,
    playIndex,
    position,
    progress,
    stop,
    scaleSize,
    seekTime,
    playIndexes(indexes) {
        if (this.playStatus && indexes.includes(this.track.pivot.order)) {
            this.WaveSurfer.play();
        } else this.WaveSurfer.stop();
    },
});
