'use strict';

export default function progress() {
    let cursor  = document.querySelector(`#mixer-track-wave-${this.track.pivot.order+1} > div`).shadowRoot.querySelector('.cursor');
    let offsetX = cursor.getBoundingClientRect().left;
    if(this.stop == false) {
        this.$emit('progress', offsetX);
    } else {
        this.$emit('progress', 250);
    }
}
