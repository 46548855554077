'use strict';

import components from "./js/components";
import data       from "./js/data";
import methods    from "./js/methods";
import computed   from "./js/computed";
import mounted    from "./js/mounted";
import props      from "./js/props";
import watch      from "./js/watch";

export default {
    name: 'Workspace',
    components,
    data,
    methods,
    computed,
    mounted,
    props,
    watch,
}