'use strict';

import ColorPicker from './components/colorpicker/ColorPicker.vue';
import { OnClickOutside  } from '@vueuse/components';
import VueResizable from 'vue-resizable';

export default({
    ColorPicker,
    OnClickOutside,
    VueResizable 
})