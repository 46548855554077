'use strict';


import setZoomTime from "@/components/workspace/js/methods/setZoomTime";

export default function zoom(event) {

    const delta = event.deltaY || event.detail || event.wheelDelta;
    const step = 1.03; //  1.015
    if(delta < 0) {
        if(this.splitSeconds !== 0.25) {
            this.scale *= step;
        }
    } else {
        if(this.scale > 1) {
            this.scale /= step;
        }
    }
    let left = 0;
    for (let i = 0; i < Object.values(this.program.tracks).length; i++) {
        left += this.trackWidth(i);
    }

    if(left > 1800){
        this.lastCount = setZoomTime(300);
    }


    if(left > 3250){
        this.lastCount = setZoomTime(150);
    }

    if(left >7500){
        this.lastCount = setZoomTime(60);
    }

    if(left >12000){
        this.lastCount = setZoomTime(30);
    }

    if(left >20000){
        this.lastCount = setZoomTime(15);
    }

    if(left >30000){
        this.lastCount = setZoomTime(10);
    }

    if(left >50000){
        this.lastCount = setZoomTime(5);
    }


    if(left >=150000){
        console.log('*****3');
        this.lastCount = setZoomTime(1);
        this.lastLeft = left * 3 - this.containerZoomWidth;
        this.containerZoomWidth = left * 3;


    } else {
        this.lastLeft = left - this.containerZoomWidth;
        this.containerZoomWidth = left;
    }
    console.log(left);

    this.left = left

}
