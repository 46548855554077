'use strict';

export default function trackWidth(index) {
    const elem = document.getElementById(`mixer-track-${index + 1}`);
    const track = this.program.tracks[index];
    const totalDuration = this.timeFormat('ss', this.program.duration);
    const width = (this.timeFormat('ss', track.duration) / totalDuration) * (this.containerWidth) * (this.scale > 1 ? this.scale : 1);
    if (elem) elem.setAttribute('data-width', width.toString());
    return width;
    // if(this.scale > 1) {
    //     return (this.timeFormat('ss', track.duration) / totalDuration) * (this.containerWidth) * (this.scale);
    // } else {
    //     return (this.timeFormat('ss', track.duration) / totalDuration) * (this.containerWidth);
    // }
}
