'use strict';

export default function playIndex(newIndex) {
    if(newIndex == this.track.pivot.order) {
        this.WaveSurfer.play();
        
        this.$emit('duration', this.duration);
        this.$emit('bpm', this.track.bpm);
        this.$emit('key', this.track.key);
    } else {
        this.WaveSurfer.stop();
    }
}