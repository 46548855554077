<template>
    <svg xmlns="http://www.w3.org/2000/svg" width="9" height="23" viewBox="0 0 9 23" fill="none">
      <path d="M1.28 2.56C1.98692 2.56 2.56 1.98692 2.56 1.28C2.56 0.573075 1.98692 0 1.28 0C0.573075 0 0 0.573075 0 1.28C0 1.98692 0.573075 2.56 1.28 2.56Z" fill="inherit"/>
      <path d="M7.42014 2.56C8.12706 2.56 8.70014 1.98692 8.70014 1.28C8.70014 0.573075 8.12706 0 7.42014 0C6.71321 0 6.14014 0.573075 6.14014 1.28C6.14014 1.98692 6.71321 2.56 7.42014 2.56Z" fill="inherit"/>
      <path d="M1.28 7.47016C1.98692 7.47016 2.56 6.89708 2.56 6.19016C2.56 5.48323 1.98692 4.91016 1.28 4.91016C0.573075 4.91016 0 5.48323 0 6.19016C0 6.89708 0.573075 7.47016 1.28 7.47016Z" fill="inherit"/>
      <path d="M7.42014 7.47016C8.12706 7.47016 8.70014 6.89708 8.70014 6.19016C8.70014 5.48323 8.12706 4.91016 7.42014 4.91016C6.71321 4.91016 6.14014 5.48323 6.14014 6.19016C6.14014 6.89708 6.71321 7.47016 7.42014 7.47016Z" fill="inherit"/>
      <path d="M1.28 12.3696C1.98692 12.3696 2.56 11.7965 2.56 11.0896C2.56 10.3826 1.98692 9.80957 1.28 9.80957C0.573075 9.80957 0 10.3826 0 11.0896C0 11.7965 0.573075 12.3696 1.28 12.3696Z" fill="inherit"/>
      <path d="M7.42014 12.3696C8.12706 12.3696 8.70014 11.7965 8.70014 11.0896C8.70014 10.3826 8.12706 9.80957 7.42014 9.80957C6.71321 9.80957 6.14014 10.3826 6.14014 11.0896C6.14014 11.7965 6.71321 12.3696 7.42014 12.3696Z" fill="inherit"/>
      <path d="M1.28 17.2797C1.98692 17.2797 2.56 16.7067 2.56 15.9997C2.56 15.2928 1.98692 14.7197 1.28 14.7197C0.573075 14.7197 0 15.2928 0 15.9997C0 16.7067 0.573075 17.2797 1.28 17.2797Z" fill="inherit"/>
      <path d="M7.42014 17.2797C8.12706 17.2797 8.70014 16.7067 8.70014 15.9997C8.70014 15.2928 8.12706 14.7197 7.42014 14.7197C6.71321 14.7197 6.14014 15.2928 6.14014 15.9997C6.14014 16.7067 6.71321 17.2797 7.42014 17.2797Z" fill="inherit"/>
      <path d="M1.28 22.1801C1.98692 22.1801 2.56 21.607 2.56 20.9001C2.56 20.1932 1.98692 19.6201 1.28 19.6201C0.573075 19.6201 0 20.1932 0 20.9001C0 21.607 0.573075 22.1801 1.28 22.1801Z" fill="inherit"/>
      <path d="M7.42014 22.1801C8.12706 22.1801 8.70014 21.607 8.70014 20.9001C8.70014 20.1932 8.12706 19.6201 7.42014 19.6201C6.71321 19.6201 6.14014 20.1932 6.14014 20.9001C6.14014 21.607 6.71321 22.1801 7.42014 22.1801Z" fill="inherit"/>
    </svg>
</template>