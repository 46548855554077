'use strict';

import createTrack    from "./methods/createTrack";
import createEnvelope from "./methods/createEnvelope";
import createCut      from "./methods/createCut";
import toolHandler    from "./methods/toolHandler";
import timeFormat     from "./methods/timeFormat";

export default({
    createTrack,
    createEnvelope,
    createCut,
    toolHandler,
    timeFormat,
    onWheel(event, track) {
        event.preventDefault()
        const workspacePlayer = document.getElementById('workspace-player');
        let width = 0;
        for (let i = 0; i <= track.pivot.order; i++) {
            let i_track = this.$parent.program.tracks[i];
            const totalDuration = this.timeFormat('ss', this.$parent.program.duration);
            let start_w = (this.timeFormat('ss', i_track.duration) / totalDuration) * this.$parent.containerWidth;
            let end_w = this.$parent.scale > 1 ? (this.timeFormat('ss', i_track.duration) / totalDuration) * (this.$parent.containerWidth) * (this.$parent.scale) : start_w;
            width += i < track.pivot.order ? (end_w - start_w) : (end_w - start_w) / 2;
        }
        workspacePlayer.scrollTo({
            left: width,
            top: 0,
            behavior: 'smooth'
        });
    },
})
