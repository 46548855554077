'use strict';

import data     from "./js/data";
import props    from "./js/props";
import computed from "./js/computed";
import methods  from "./js/methods";
import watch    from "./js/watch";

export default {
    name: 'Controls',
    data,
    props,
    computed,
    methods,
    watch,
}