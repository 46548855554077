<section class="controls">
    <div class="controls__content">
        <button id="mixer-play" class="controls__btn circle-btn" :class="{active: ready, play: !play, pause: play}" @click="ready ? $emit('play') : '';"/>
        <button id="mixer-stop" class="controls__btn circle-btn" :class="{active: position > 0}" @click="$emit('stop');"/>

        <div class="controls__info" :class="{since: since, until: !since, active: play}">
            <span v-if="selectedMode === 'time'" v-text="timerPosition" @click="since = !since"/>
            <span v-else-if="selectedMode === 'bpm'" v-text="bpm ?? 'BPM'"/>
            <span v-else v-text="key ?? '-'"/>
        </div>

        <button v-for="(btn, index) in modes" :key="index" v-text="btn.name" class="controls__btn circle-btn" :class="{active: btn.isActive}" @click="selectMode(index)"/>
    </div>
</section>