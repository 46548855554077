'use strict';

export default function() {
    return {
        isHere: true,
        ready: false,
        activeTool: null,
        currentTrack: {
            index: 0,
            play: false,
            stop: false,
            progress: null,
            position: 0,
            duration: 0,
            bpm: null,
            key: null,
            indexes: [0],
            positions: {},
            time_watching: true,
        },
        scale: 3,
        containerWidth: null,
        containerZoomWidth: null,
        seekTime: null,
        splitSeconds: 300,
        currentSingleChannel: 1,
        channels: [
            {
                id: 0,
                isSingleEnabled: false,
                trackList: [],
            },
            {
                id: 1,
                isSingleEnabled: true,
                trackList: [],
            },
        ],
    }
}
