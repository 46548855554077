'use strict';

export default function splitedChannels() {
    for (let i = 0; i < this.program.tracks.length; i++) {
        if (i % 2 !== 0) {
            this.channels[1].trackList.push(this.program.tracks[i]);
        } else {
            this.channels[0].trackList.push(this.program.tracks[i]);
        }
    }

    return this.channels;
}
