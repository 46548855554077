'use strict';

import EnvelopePlugin from 'wavesurfer.js/dist/plugins/envelope';

export default function createEnvelope() {
    this.envelope = this.WaveSurfer.registerPlugin(
        EnvelopePlugin.create({
          volume: 0.8,
          lineColor: 'rgba(255, 255, 255, 0.85)',
          lineWidth: 1.5,
          dragPointSize: 15,
          dragPointFill: '#FFF057',
          dragPointStroke: 'rgba(0, 0, 0, 0.75)',
          points: [
            { time: 10, volume: 0.8 },
          ],
        }),
      );

    let lastSecond =  Math.floor(this.WaveSurfer.getDuration());

    this.envelope.addPoint({time: lastSecond - 10, volume: 0.8});
}