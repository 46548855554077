<MixerLayout>
    <template #header>
        <Header/>
    </template>

    <template #workspace>
        <workspace :program="program" :tools="tools"/>
    </template>

    <template #tools>
        <tools @tools="e => tools = e"/>
    </template>
</MixerLayout>